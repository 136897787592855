.header {
    width: 1920px;
    height: 77px;
    background-color: #ffffff;
    overflow: visible;
}
.header-item {
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.3;
    color: #999999;
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    text-decoration: none;
}
.header-item:hover {
    color: #000000;
}
.title-small {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0px;
    line-height: 1.3;
    color: #000000;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    font-style: normal;
}
.body {
    margin: 20px;
    width: 320px;
    height: 440px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    color: #000000;
    letter-spacing: 0px;
    line-height: 1.3;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    text-align: left;
}
.landing-card {
    margin-top: 20px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 360px;
}
/*.icon {
    font-size: 40px;
    color: #0055ff;
    position: relative;
    right: 40%;
    margin-bottom: 20px;
    margin-top: 20px;
}*/
.text {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    letter-spacing: 0px;
    line-height: 1.3;
    text-align: center;
    font-weight: 800;
    font-style: normal;
    font-size: 28px;
}
