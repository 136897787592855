.header-icon {
    color: #ffffff;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
}
.header-icon-disabled {
    color: #777777;
    margin-top: 20px;
    margin-left: 20px;
    cursor: not-allowed;
}
.header-icon:hover {
    color: rgb(0, 85, 255);
}

th {
    padding: 0 !important;
    margin: 0 !important;
}
tr {
    /* padding: 0px !important; */
    width: 100%;
    display: inline-table;
    table-layout: fixed;
}
