.alert {
  border-radius: 14px;
}

/* default button */
.btn {
  height: 3em;
  padding: 0em 1.8em 0em 1.8em;
  border-radius: 14px;
}
/* big button */
.btn-lg {
  height: 3.5em;
  padding: 0em 2.4em 0em 2.4em;
}
/* small button */
.btn-sm {
  height: 2.5em;
  padding: 0em 1.2em 0em 1.2em;
}
.btn-outline-primary {
  border: 0.2em solid;
  background-color: #fff;
}
.display-2 {
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 1.3;
  color: #000000;
  text-align: center;
}
.modal-full-screen {
  width: 100% !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
  margin: 0;
  padding: 0;
}

.w-500{
  width: 41vw;
}
