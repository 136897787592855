#header {
    padding: 0 !important;
    max-width: auto;
    background-color: #ffffff;
    overflow: visible;
    line-height: 1.4 !important;
}
.pro-sidebar-header {
    height: 1.5em;
}
#header .pro-sidebar {
    height: calc(var(--vh, 1vh) * 100 - 83px) !important;
    width: 13.75em;
    z-index: 100 !important;
}
#header .closemenu {
    margin: auto;
    margin-right: 1%;
    width: 1.375em;
    height: 2.5em;
    overflow: visible;
    color: #000;
    border-radius: 50%;
    cursor: pointer;
}
#header .pro-sidebar.collapsed {
    width: 5em;
}
#header .pro-sidebar-inner {
    background-color: white;
}
#header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 1em;
    font-weight: 700;
    margin-left: 1.875em;
    color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding-top: 1px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #999999;
    margin: 0;
    padding-right: 1.25em;
}
#submenu-item .pro-inner-item {
    padding-top: 0 !important;
}
#header
    .pro-sidebar-inner
    .pro-sidebar-layout
    ul
    .pro-inner-item
    .pro-icon-wrapper {
    background-color: #ffffff;
    color: #999999;
    border-radius: 3px;
}
#header
    .pro-sidebar-inner
    .pro-sidebar-layout
    ul
    .pro-inner-item
    .pro-icon-wrapper
    .pro-item-content {
    color: #000;
}

#header .logo {
    padding: 20px;
}

.content {
    background-color: white;
}
.pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu:hover
    > .pro-inner-list-item {
    display: none;
}
.pro-sidebar .pro-menu a:hover {
    color: #000000;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 16px !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active .pro-inner-item {
    color: #000000 !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active .pro-inner-item .pro-icon-wrapper {
    color: #000000 !important;
}
.pro-sidebar .pro-sub-menu.open > .pro-inner-item .pro-item-content {
    color: #000000 !important;
}
.pro-sidebar
    .pro-sub-menu.open
    > .pro-inner-item
    .pro-arrow-wrapper
    .pro-arrow {
    border-color: #000000 !important;
}
.pro-sidebar .pro-sub-menu.open > .pro-inner-item .pro-icon-wrapper {
    color: #000000 !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    outline: none;
    color: #000000;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item:before {
    content: none !important;
}
.activated {
    color: #000;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-top: none !important;
    color: #000000 !important;
    cursor: pointer;
}
.pro-sidebar:not(.collapsed) .popper-inner {
    display: none;
}
li .pro-menu-item {
    padding: 0;
    padding-right: 5px;
    margin: 0;
}
.pro-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
#submenu-item {
    margin-top: 5px !important;
    font-size: 14px;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    border-color: #999999;
    padding: 0.25ch !important;
    transform: rotate(45deg) !important;
}
.pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu.open
    > .pro-inner-item
    > .pro-arrow-wrapper
    .pro-arrow {
    transform: rotate(-135deg) !important;
}
.pro-arrow-wrapper {
    padding-bottom: 0.8ch !important;
}
