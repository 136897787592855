@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
/* set the overriding variables */
$theme-colors: (
  "primary": #0055ff,
  "blue": #0055ff,
  "black": #000,
  "gray": rgba(153, 153, 153, 0.3),
  "secondary": #999999,
  "danger": #ff2b00,
  "success": #32c832,
  "bg-gray": rgba(153, 153, 153, 0.1),
  "tag-yellow": #ffaa00,
  "tag-pink": #ff0055,
  "tag-green": #00ff55,
  "tag-blue": #2b00ff,
  "pressed-blue": #3b7cff,
  "pressed-gray": #bfbfbf,
  "pressed-red": #ff5c3b,
  "disabled-gray": #f3f3f3,
  "disabled-blue": rgba(0, 85, 255, 0.3),
);
$border-widths: (
    1: 14px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1920px,
    xxxl: 2700px,
);
$border-radius: 1.4ch;
/* override the !default vars with the values we set above */
@import '~bootstrap/scss/bootstrap';

@import '../node_modules/react-day-picker/lib/style';

