html {
  height: -webkit-fill-available;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #000000;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  overflow-x: hidden;
}
ul {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input-label {
  font-weight: 600;
  font-size: 16px;
}
.input {
  background-color: rgba(153, 153, 153, 0.1);
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  border-radius: 1.4ch;
  border: 0;
}
.input::placeholder {
  color: #999999;
  font-size: 14px;
}
.input:hover:not([disabled]),
.input:focus:not([disabled]),
.input:active:not([disabled]) {
  outline: none;
  background-color: rgba(153, 153, 153, 0.3);
  color: #000000;
}
.input:focus:not([disabled]) {
  box-shadow: 0 0 0 0.2rem rgba(0, 85, 255, 25%);
}
.input:disabled {
  border: 2px solid rgba(153, 153, 153, 0.1);
  background-color: transparent;
  -webkit-text-fill-color: #000000;
}
.input:disabled::placeholder {
  color: transparent;
}
.descr-label {
  font-weight: 600;
  font-size: 16px;
}
.highlight-label {
  font-weight: 800;
  font-size: 16px;
  margin-left: 5px;
}
.action-label {
  font-weight: 700;
  font-size: 16px;
}
.alert {
  border-radius: 1.4ch;
}
.border-2 {
  border-width: 0.3ch !important;
}
/* .input-group {
  height: 3em;
}
.form-control {
  height: 3em;
} */
/* default button */
.btn {
  border: none;
  height: 3em;
  padding: 0em 1.8em 0em 1.8em;
  border-radius: 1.4ch;
  line-height: 1.4;
  /* box-shadow: 0px 2px 10px 0px rgb(153 153 153 / 30%); */
  /* overflow: visible; */
  /* add commun style */
}
/* big button */
.btn-lg {
  height: 3.5rem;
  padding: 0rem 2.4rem 0rem 2.4rem;
}
/* small button */
.btn-sm {
  height: 2.5rem;
  padding: 0rem 1.2rem 0rem 1.2rem;
}
.btn-circle {
  padding: 0px !important;
  width: 3rem !important;
  border-radius: 50% !important;
}
.btn-lg-circle {
  padding: 0px !important;
  width: 3.5rem !important;
  border-radius: 50% !important;
}
.btn-sm-circle {
  padding: 0px !important;
  width: 2.5rem !important;
  border-radius: 50% !important;
}
.btn-outline-primary {
  border: 0.2em solid;
  background-color: #fff;
}
.btn:disabled {
  opacity: 30%;
}
.btn-width {
  width: 13.5rem;
  height: 60px;
}
.btn-width-unset {
  width: unset;
}
.btn:focus {
  box-shadow: none !important;
}
.btn-l {
  height: 3.75rem;
  width: 12.5rem;
  color: #ffffff;
  border: 0;
  font-weight: 700;
  font-size: 16px;
}
.btn-l:focus,
.btn-l:active {
  outline: none !important;
  box-shadow: none !important;
}
.btn-outline-primary:not(.no-hover):hover {
  background-color: #0055ff !important;
  border-color: #0055ff !important;
}
.btn-dark {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:not(.no-hover):hover {
  color: #fff;
  background-color: #0055ff;
  border-color: #0055ff;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #999999 !important;
  border-color: #999999 !important;
}
.btn-dark:not(.no-focus):focus,
.btn-dark.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-off {
  color: #999999;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-off:hover {
  color: #999999;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.btn-grey {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.btn-grey:not(.no-hover):hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-grey:focus,
.btn-grey.focus {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #fff;
  background-color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}
.btn-delete {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.btn-delete:not(.no-hover):hover {
  color: #fff;
  background-color: #ff2b00;
  border-color: #ff2b00;
}
.btn-delete:focus,
.btn-delete.focus {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.btn-delete:not(:disabled):not(.disabled):active,
.btn-delete:not(:disabled):not(.disabled).active,
.show > .btn-delete.dropdown-toggle {
  color: #fff;
  background-color: #ff5c3b !important;
  border-color: #ff5c3b !important;
}
.btn-gray {
  color: #000;
  background-color: rgba(153, 153, 153, 0.1);
  border-color: rgba(153, 153, 153, 0.1);
}
.btn-gray:not(.no-hover):hover {
  color: #fff;
  background-color: rgba(153, 153, 153, 0.3);
  border-color: rgba(153, 153, 153, 0.3);
}
.btn-gray:focus,
.btn-gray.focus {
  color: #000;
  background-color: rgba(153, 153, 153, 0.1);
  border-color: rgba(153, 153, 153, 0.1);
}
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3b7cff;
  border-color: #3b7cff;
}
/* .btn-primary:not(.no-hover):hover */
.btn-primary:hover:not([disabled]) {
  background-color: #000000;
  border: #000000;
}
.open
  > .dropdown-toggle.btn-grey
  .btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #ffffff;
}
.btn-secondary {
  outline: none;
  box-shadow: none !important;
  border: none;
  text-align: left;
}
th .btn-secondary {
  height: 72px;
}
.btn-secondary:not(.no-hover):hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.open
  > .dropdown-toggle.btn-secondary
  .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #999999;
  border-color: #999999;
  color: #ffffff;
}
.table-dropdown-menu.dropdown-menu {
  background-color: #999999;
  color: white;
  /* margin-top: -10px; */
  border: none;
}
.color-gray {
  background-color: rgba(255, 255, 255, 0);
  color: #999999;
}
.text-white {
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
}
.dropdown-toggle:focus,
.dropdown-toggle::after {
  display: none;
}
.display-1 {
  font-size: 5.9vw;
}
.display-5 {
  font-size: 2vw;
}
.display-12 {
  font-size: 12px;
}
.display-14 {
  font-size: 1.4ch;
}
.display-16 {
  font-size: 16px !important;
}
.display-18 {
  font-size: 18px !important;
}
.display-20 {
  font-size: 20px !important;
}
.display-24 {
  font-size: 24px !important;
}
.display-28 {
  font-size: 28px !important;
}
.display-32 {
  font-size: 32px;
}
.display-36 {
  font-size: 36px;
}
.display-40 {
  font-size: 40px;
}
.display-48 {
  font-size: 48px;
}
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  /* == font-weight-normal */
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  /* == font-weight-bold */
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900;
}
.card {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3) !important;
  border: 0;
  line-height: 1.4;
  /* background-color: #ffffff; */
  /* overflow: visible; */
  border-radius: 1.4ch;
}
.card-primary {
  box-shadow: 0px 2px 10px 0px rgba(0, 85, 255, 0.3) !important;
  border: 2px solid #0055ff;
  line-height: 1.4;
  border-radius: 1.4ch;
}
.card-danger {
  box-shadow: 0px 2px 10px 0px rgba(255, 43, 0, 0.3) !important;
  border: 2px solid #ff2b00;
  line-height: 1.4;
  border-radius: 1.4ch;
}
.card-body {
  padding: 1rem;
}
.grayscale img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
.invite-user-container-width {
  width: calc(100% - 1rem) !important;
  overflow: hidden !important;
}
.sessionInvitationContainer {
  padding-top: 24px;
  margin-left: 16px;
  height: calc(var(--vh, 1vh) * 100 - 272px);
  overflow-y: auto;
}
.rank {
  width: auto;
  height: auto;
  overflow: visible;
  white-space: pre;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  font-weight: 800;
  font-size: 48px;
}
.io {
  width: auto; /* 238px */
  height: auto; /* 76px */
  overflow: visible;
  white-space: pre;
  color: #0055ff;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  font-weight: 800;
  font-style: normal;
  font-size: 48px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
.modal-full-screen {
  width: 100% !important;
  height: 100vh !important;
  max-width: none !important;
  max-height: none !important;
  margin: 0;
  padding: 0;
}
.radius-14 {
  border-radius: 1.4ch;
}
.radius-buttom-14 {
  border-bottom-left-radius: 1.4ch;
  border-bottom-right-radius: 1.4ch;
}
.table thead th {
  vertical-align: middle !important;
  height: 72px !important;
  border-bottom: 2px solid rgba(153, 153, 153, 0.1) !important;
}
.w-4 {
  width: 4rem;
}
.minem-w-5 {
  width: 5rem !important;
}
.minem-w-6 {
  width: 6rem !important;
}
.minem-w-7 {
  width: 8rem !important;
}
.minem-w-8 {
  width: 8rem !important;
}
.minem-w-9 {
  width: 9rem !important;
}
.minem-w-10 {
  width: 10rem !important;
}
.minem-w-12 {
  width: 12rem !important;
}
.minem-w-14 {
  width: 14rem !important;
}
.minem-w-15 {
  width: 15rem !important;
}
.minem-w-17 {
  width: 17rem !important;
}
.minem-w-20 {
  width: 20rem !important;
}
.w-99 {
  width: 99%;
}
.w-308 {
  width: 308px;
}
.w-3 {
  width: 3rem;
  min-width: 3rem;
}
.w-5 {
  width: 5rem;
  min-width: 5rem;
}
.w-6 {
  width: 6rem;
}
.w-8 {
  width: 8rem;
}
.w-9 {
  width: 9rem;
}
.w-11 {
  width: 11rem;
  min-width: 11rem;
}
.w-13 {
  width: 18rem;
  min-width: 18rem;
}
.mw-116 {
  min-width: 116px;
}
.mw-120 {
  min-width: 120px;
}
.mw-168 {
  min-width: 168px;
}
.mw_40 {
  max-width: 40ch;
}
.mw-700 {
  max-width: 700px;
}
.minem-h-6 {
  height: 6rem !important;
}
.minem-h-8 {
  height: 8rem !important;
}
.minem-h-9 {
  height: 9rem !important;
}
.filter-input {
  background-color: rgb(255, 255, 255);
  width: 60%;
  margin-left: 25%;
  border: none;
  font-size: 12px;
  padding: 3%;
  color: #000000 !important;
}
.filter-input::placeholder {
  color: #999999;
}
.filter-input:hover,
.filter-input:focus,
.filter-input:active {
  outline: none;
  color: #999999;
}
.hover-grey:hover {
  background-color: rgb(153, 153, 153, 0.3);
}
.shadow {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3) !important;
  border: 0;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
.landing {
  margin-top: 83px;
}
table {
  line-height: 1.4;
  color: #000000 !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(153, 153, 153, 0.3);
}
.rk-height-330 {
  height: calc(var(--vh, 1vh) * 100 - 330px) !important;
}
.rk-modal-level-1 {
  width: calc(100% - 1rem) !important;
  margin-top: 10px !important;
}
.rk-modal-body-level-1 {
  height: calc(var(--vh, 1vh) * 100 - 212px) !important;
  overflow-y: auto;
}
.rk-modal-level-2 {
  width: calc(100% - 1rem) !important;
  margin-top: 40px !important;
}
.rk-modal-body-level-2 {
  height: calc(var(--vh, 1vh) * 100 - 242px) !important;
  overflow-y: auto;
}
.rk-modal-level-3 {
  width: calc(100% - 1rem) !important;
  margin-top: 70px !important;
}
.rk-modal-body-level-3 {
  height: calc(var(--vh, 1vh) * 100 - 272px) !important;
  overflow-y: auto;
}
thead,
tbody {
  display: block;
}
.rk-deprecated Table tbody {
  color: #000000;
  font-weight: 600;
  height: calc(var(--vh, 1vh) * 100 - 330px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
#dashcards .card {
  max-width: 25em;
  line-height: 1.4 !important;
}

/* .no-efx {
    pointer-events: none;
} */

td {
  padding: 1rem !important;
}
.checkrow {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 2ch;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkrow input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: rgba(255, 255, 255);
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
/* .checkrow:hover input ~ .checkmark {
  background-color: #fff;
} */

/* When the checkbox is checked, add a blue background */
.checkrow input:checked ~ .checkmark {
  background-color: #0055ff;
  border-radius: 6px;
  /* border: solid white;
  border-width: 1px; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkrow input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkrow .checkmark:after {
  margin-left: 5px;
  margin-top: 3px;
  width: 5px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* The Modal (background) */
.rk-modal {
  /* padding: 8px; */
  border-radius: 1.4ch;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3) !important;
  border: 0;
  line-height: 1.4;
  display: none; /* Hidden by default */
  position: absolute;
  z-index: 3; /* Sit on top */
  padding-bottom: 8px;
  left: auto;
  top: 30px;
  width: auto; /* Full width */
  height: auto; /* Full height */
  /* Enable scroll if needed */
  overflow: auto;
  /* Fallback color */
  /* background-color: rgb(0,0,0);  */
  /* Black w/ opacity */
  /* background-color: rgba(0,0,0,0.4);  */
}
/* Modal Content */
.rk-modal-content {
  border-radius: 1.4ch;
  background-color: #fff;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
}
.modal-header {
  border-bottom: 2px solid rgba(153, 153, 153, 0.1) !important;
}
.wrapper {
  display: flex;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 85px) !important;
  align-items: stretch;
}
.container-full {
  width: calc(100% - 95px) !important;
}
.container-reduced {
  width: calc(100% - 235px) !important;
}
.rk-clickable-icon-dark {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #000000;
  border-color: #000000;
  stroke-width: 3;
}
.rk-clickable-icon-dark:not(.no-hover):hover {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}
.rk-clickable-icon-dark:active {
  color: #ffffff;
  border-color: #999999;
  background-color: #999999;
}
.rk-clickable-icon-light {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #ffffff;
  border-color: #ffffff;
  stroke-width: 3;
}
.rk-clickable-icon-light:not(.no-hover):hover {
  color: #0055ff;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-clickable-icon-light:active {
  color: #3b7cff;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-clickable-icon-primary {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #0055ff;
  border-color: #0055ff;
  stroke-width: 3;
}
.rk-clickable-icon-primary:not(.no-hover):hover {
  color: #ffffff;
  border-color: #0055ff;
  background-color: #0055ff;
}
.rk-clickable-icon-primary:active {
  color: #ffffff;
  border-color: #3b7cff;
  background-color: #3b7cff;
}
.rk-clickable-icon-secondary {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #999999;
  border-color: #999999;
  stroke-width: 3;
}
.rk-clickable-icon-secondary:not(.no-hover):hover {
  color: #ffffff;
  border-color: #999999;
  background-color: #999999;
}
.rk-clickable-icon-secondary:active {
  color: #ffffff;
  border-color: #bfbfbf;
  background-color: #bfbfbf;
}
.rk-clickable-icon-white {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #ffffff;
  border-color: #ffffff;
  stroke-width: 3;
}
.rk-clickable-icon-white:not(.no-hover):hover {
  color: #000000;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-clickable-icon-white:active {
  color: #bfbfbf;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-clickable-icon-danger {
  border-radius: 50% !important;
  border: 2px solid;
  cursor: pointer;
  color: #ffffff;
  border-color: #ffffff;
  stroke-width: 3;
}
.rk-clickable-icon-danger:not(.no-hover):hover {
  color: #ff2b00;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-clickable-icon-danger:active {
  color: #ff5c3b;
  border-color: #ffffff;
  background-color: #ffffff;
}
.rk-alert {
  /* display: none; */
  position: fixed !important;
  margin: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  color: #ffffff;
  height: 4ch;
  z-index: 1000 !important;
}
#confirmation-modal .modal-content {
  border-radius: 1.4ch;
}
#confirmation-modal .modal-body {
  max-width: 50rem;
  min-height: 13rem;
  border-radius: 1.4ch;
}
#confirmation-modal .btn {
  height: 175%;
}
.session-search-input {
  color: rgb(0, 0, 0) !important;
  font-weight: 700;
  font-size: 16px;
  margin-left: 2px;
  padding-left: 54px;
  border: 5px solid black !important;
  height: 40px !important;
}
.session-invite-search-container {
  margin-bottom: 24px;
  position: relative;
  max-width: 600px;
}
.add-session-btn-container {
  margin-top: 16px;
  display: grid;
}
.d-grid {
  display: grid;
}
.session-details-col-display {
  display: block;
  margin-left: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.profil-container {
  height: calc(var(--vh, 1vh) * 100 - 310px) !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.modal-labels {
  margin-top: 1.75em;
  margin-bottom: 1.2em;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  color: #000000;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
}
.modal-input {
  background-color: rgba(153, 153, 153, 0.1);
  border: rgb(255, 255, 255);
  border-width: 2px;
  width: 80%;
  height: 2.5rem;
}
.modal-input:focus {
  background-color: rgba(153, 153, 153, 0.1);
  border: #0055ff;
}
.modal-card {
  margin-top: 1.75em;
  width: 100px;
  height: 100px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 1.2;
  border-radius: 6px;
}
.scoring {
  width: 100%;
  height: 80px;
  background-color: rgba(153, 153, 153, 0.3);
  overflow: visible;
  padding: 0;
  margin-left: 0;
}
.text-scoring {
  width: 1600px;
  height: 40px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin: 25px;
}
.info {
  width: 100%;
  height: 80px;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  background-color: rgba(0, 85, 255, 0.1);
  overflow: visible;
  border-radius: 1.4ch;
}
.supprimer:not(.no-hover):hover {
  background-color: red;
}
.assignation {
  width: 48.75em;
  height: 41.375em;
}
.tags {
  width: 50em;
  height: 71.25em;
}
.h-100-330 {
  height: calc(var(--vh, 1vh) * 100 - 330px) !important;
  overflow-x: hidden !important;
}
.h-100-207 {
  height: calc(var(--vh, 1vh) * 100 - 207px) !important;
  overflow-x: hidden !important;
}
.h-menu {
  height: calc(var(--vh, 1vh) * 100 - 280px) !important;
}
.no-outline {
  outline: none;
  box-shadow: none;
}
.no-outline:focus,
.no-outline:active,
.no-outline:focus.no-outline:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.Assignment-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.Assignment-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(153, 153, 153, 0.3);
  width: 100%;
  height: auto;
}

.Assignment-row:nth-child(2n + 1) {
  background-color: #ffffff !important;
}

.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.delete-icon {
  margin-right: 5px;
  color: rgb(153, 153, 153);
}

.edit-icon {
  color: rgb(153, 153, 153);
}
.profil-image {
  height: 5rem;
  width: 5rem;
  min-width: 5rem;
  border-radius: 50%;
}
.profil-icon {
  height: 2.6rem;
  width: 2.6rem;
  border-radius: 50%;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}
.Assignment-dropdown {
  background: none;
}
.fixed-col {
  width: 220px;
  padding-right: 0%;
  margin-right: 0%;
}
[type='date']::-webkit-inner-spin-button {
  display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}
[type='date']:focus {
  outline: none;
}
.tag {
  width: 100px;
  height: 42px;
  color: #ffffff;
  margin: 4px;
  padding: 0px 4px;
  font-size: 16px;
  font-weight: 700;
  display: block;
}
.tag-sm {
  width: 60px;
  height: 20px;
  color: #ffffff;
  margin: 4px;
  padding: 0px 4px;
  font-size: 10px;
  font-weight: 700;
}
.tag:not(.no-hover):hover {
  background-color: #000000 !important;
}
::-webkit-scrollbar {
  border-radius: 1.4ch;
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 1.4ch;
  border: 3px solid #ffffff;
  /* position: fixed; */
  right: 20px;
}
.scroll-x {
  overflow-x: scroll !important;
}
.scroll-y {
  overflow-y: scroll !important;
}
@supports (not (-moz-appearance: none)) {
  .scrollbar-light::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .scrollbar-light::-webkit-scrollbar-thumb {
    background: #ffffff;
    border: 0px;
  }
}
@supports (-moz-appearance: none) {
  html {
    scrollbar-color: #999999 #ffffff;
    scrollbar-width: 6px;
  }
  .scrollbar-light {
    scrollbar-color: #ffffff #999999 !important;
  }
}
.scroll-y-on-hover {
  overflow-y: scroll !important;
  scrollbar-color: #999999 #ffffff;
}
.scroll-x-on-hover {
  overflow-x: scroll;
  scrollbar-color: #999999 #ffffff;
}
@media (hover: hover) {
  /* Styles for hover-able devices */
  .scroll-y-on-hover {
    overflow: hidden !important;
    /* scrollbar-width: 8px; */
  }
  .scroll-x-on-hover {
    overflow: hidden !important;
  }
  .scroll-y-on-hover:hover,
  .scroll-y-on-hover:active {
    overflow-y: scroll !important;
    scrollbar-color: #999999 #ffffff;
  }
  .scroll-x-on-hover:hover,
  .scroll-x-on-hover:active {
    overflow-x: scroll !important;
    scrollbar-color: #999999 #ffffff;
  }
  body {
    overflow-x: hidden !important;
  }
}
.selected-tag {
  background-color: #003bb4 !important;
  color: #4c87ff !important;
}
.Assignment-row:nth-child(2n) .form-control:focus {
  background-color: #e0e0e0 !important;
}
.selected-img {
  filter: url('./RankioFilter.svg#dark-blue-sepia') !important;
}
.workspace-header-style {
  width: 100%;
}
.workspace-title {
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: left;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.v-center {
  align-items: center;
}
.h-center {
  justify-content: center;
}
.workspace-body-Controller {
  overflow-y: scroll;
  padding-bottom: 20px;
  height: calc(100vh - 190px);
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.column {
  flex-direction: column;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.m-0 {
  margin: 0 !important;
}
.mr-1rem {
  margin-right: 1rem;
}
.mr-3 {
  margin-right: 3%;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mt-20 {
  margin-top: 20px;
}
.mr-20rem {
  margin-right: 20rem !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-30rem {
  margin-right: 30rem !important;
}
.mt-33 {
  margin-top: 33px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-40 {
  margin-bottom: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-55 {
  margin-top: 55px !important;
}
.ml-55 {
  margin-left: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.ml-60 {
  margin-left: 60px;
}
.mt-70 {
  margin-top: 70px !important;
}
.mr-80 {
  margin-right: 80px;
}
.mt-100 {
  margin-top: 100px !important;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-120 {
  margin-left: 120px;
}
.ml-210 {
  margin-left: 210px;
}
.ml-240 {
  margin-left: 240px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pr-35 {
  padding-right: 35px;
}
.app-url {
  width: 100px;
  height: 37px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}
.frame-content {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  overflow: visible;
  border-radius: 1.4ch;
}
.form-group {
  margin-bottom: 0px;
}
.wrap {
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.formula-header {
  width: 200px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #999999;
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.first-separator {
  margin: auto;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 20px;
  background-color: #ffffff;
  overflow: visible;
  border-color: #999999;
  border-style: solid;
  border-top-width: 5px;
  border-bottom-width: 0px;
  border-left-width: 5px;
  border-right-width: 5px;
}

.business-separator {
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 20px;
  background-color: #ffffff;
  overflow: visible;
  border-color: #999999;
  border-style: solid;
  border-top-width: 5px;
  border-bottom-width: 0px;
  border-left-width: 5px;
  border-right-width: 5px;
}
.business-value {
  width: 200px !important;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.time-criticality {
  width: 210px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.sub-axis {
  width: 200px;
  /* min-width: fit-content; */
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.risk-reduction {
  width: 390px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.formula-plus {
  width: 60px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.wsjf-container {
  padding: 20px;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 1.4ch;
}
.wsjf-text {
  width: 100px;
  height: 60px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #0055ff;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.second-separator {
  box-sizing: border-box;
  height: 5px;
  background-color: #ffffff;
  overflow: visible;
  border-color: #000000;
  border-style: solid;
  border-top-width: 5px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.effort-text {
  width: 160px;
  height: 80px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.effort-container {
  width: 200px;
  height: 100px;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 1.4ch;
  padding: 20px;
}
.axis-val {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 1.3;
  text-align: center;
  background-color: #000000;
  border-radius: 50%;
}
.sub-axis-val {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.3;
  text-align: center;
  background-color: #000000;
  border-radius: 50%;
}
.relative {
  position: relative;
}
#dropdownWorkspace .btn-primary.dropdown-toggle {
  border: none;
  border-color: #ffffff;
  box-shadow: none;
}
#dropdownWorkspace .dropdown-toggle::after {
  display: inline-block;
}
#dropdownWorkspace .dropdown-toggle {
  background-color: inherit;
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-overflow: ellipsis;
}
#view-formula .modal-body {
  height: calc(100vh - 210px);
}
#view-formula .formula {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  white-space: nowrap;
}
.AxeContainer {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  border-radius: 1.4ch;
  max-width: 650px;
}
.optionContainer {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  border-radius: 1.4ch;
  max-width: 1000px;
}
.optionContainerTotal {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  border-radius: 1.4ch;
  max-width: 1000px;
}
.axesCollapseContainer {
  margin-left: calc(2.2rem + 20px);
  max-width: 38.7ch;
}
.switchButtonStyle {
  margin-left: 2%;
}
.custom-switch .custom-control-label::before {
  height: 24px;
  width: 50px;
  border-radius: 1.4ch;
  background-color: #999999;
  border: none;
}
.custom-switch .custom-control-label::after {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: 0;
  left: -36px;
  background: #ffffff;
}

.custom-control-label::before {
  top: 0px;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.6rem);
}

.axesContainer {
  overflow: auto;
}

.redContainer {
  color: #ff2b00 !important;
}
.redBorder {
  border: #ff2b00 solid 2px !important;
}
.default-wokspace-image {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}
.ws-def-img {
  width: 80px;
  height: 80px;
  background-color: #0055ff;
  border-radius: 50%;
  color: white;
}
.formula-content {
  width: 1400px;
  height: 100%;
  /* overflow: auto; */
}
.axe-priorisation-btn-container {
  padding-right: 35px;
}
#workspace_photo_modal {
  max-width: 500px;
}
.axeModalContainerClassname {
  background-color: rgba(255, 43, 0, 0.1);
  padding-bottom: 40px;
  border-radius: 1.4ch;
}
.ficheUserContainerClassname {
  background-color: rgba(0, 85, 255, 0.1);
  padding-bottom: 48px;
  border-radius: 1.4ch;
}
.ficheUserModalContentClassName {
  border-color: rgba(0, 85, 255, 0.1);
  border-radius: 1.4ch;
  border-width: 0px !important;
}
.axeModalContentClassName {
  border-color: rgba(255, 43, 0, 0.1);
  border-radius: 1.4ch;
  border-width: 0px !important;
}
.axeModalcloseIcon {
  margin-top: 20px;
  margin-right: 20px;
  text-align: end;
}
.file-utilisateur-alert-icon {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 16px;
  text-align: end;
  color: #0055ff;
}
.axesContainerTextWithIconClassname {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}
.axesAlertIconClassName {
  margin-left: 16px;
  margin-right: 48px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.axeModalFirstBtn {
  background-color: #999999;
  margin-right: 40px;
  width: 7rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 16px;
}
.axeModalFirstBtn:hover,
.axeModalFirstBtn:active,
.axeModalFirstBtn:focus {
  background-color: #999999;
}
.axeModalSecondBtn,
.axeModalSecondBtn:hover,
.axeModalSecondBtn:active,
.axeModalSecondBtn:focus {
  background-color: #0055ff;
  height: 2.5rem;
  width: 7rem;
  font-weight: bold;
  font-size: 16px;
}
.delete-workspace {
  min-width: 900px;
  max-width: 900px;
}
.axeModalTextClassName {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
}
.axeValueInputDetails {
  position: absolute;
  top: 5px;
  right: 55px;
  color: #000000;
  font-size: 16px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
}
.tags-color {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin: 4px;
}
.tag-name {
  width: 100px;
  height: 40px;
  border-radius: 1.4ch;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}
#tags-dropdown .dropdown-menu {
  min-width: 40px;
  width: fit-content !important;
  padding: 8px !important;
  transform: translate(-3px, 40px) !important;
}
.axeValueInputDetailsSmall {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
}
.axeDisplayRowClassnameBtn {
  display: flex;
  align-items: center;
}
.deleteBtnAxes[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
#header .pro-sidebar {
  height: calc(var(--vh, 1vh) * 100 - 83px) !important;
  width: 13.75em;
  z-index: 3 !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border: none;
  background-color: #0055ff;
}
.tagsBtnClassnameDetails {
  width: 180px;
  font-size: 16px;
}
.axeBtnClassnameDetails {
  width: 200px;
  font-size: 16px;
  font-weight: 700;
}
.invitationContainer {
  padding-top: 24px;
  margin-left: 16px;
  height: calc(var(--vh, 1vh) * 100 - 209px);
  overflow-y: auto;
}

.user-cards {
  justify-content: space-around;
  height: 100%;
}
.user-invite {
  width: 100%;
}
.user-invite button {
  max-width: 25em;
  width: 100%;
  height: 100%;
  margin-top: 0px !important;
}

.axecontentClassname {
  font-size: 13px;
}
.session-details-title {
  color: white !important;
}
.invitationArrowIcon {
  color: #ffffff;
  position: absolute;
  top: -3px;
  left: 36.5ch;
  border-radius: 1.4ch;
}
.session_invitation_arrow_icon {
  color: #ffffff;
  position: absolute;
  top: -3px;
  left: 46.5ch;
  border-radius: 1.4ch;
}
.invitationArrowIconDetails {
  position: absolute;
  bottom: 7.2px;
  right: 14.4px;
}
.invitationArrowContainer {
  position: relative;
  margin-bottom: 24px;
}
.listInvitedUserContainer {
  margin-top: 16px;
  width: 620px;
  border-radius: 1.4ch;
  border: 1px solid rgba(153, 153, 153, 0.3);
  margin-bottom: 72px;
}
.session-first-table {
  width: 680px;
  border-radius: 1.4ch;
  border: 0;
}
.invitationListeDetailsFirstCol {
  height: 66px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.session-first-col {
  height: 66px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.session-details-first-col {
  height: 66px;
  display: flex;
  align-items: center;
}
.invitationListeDetailsSecondCol {
  height: 66px;
  display: flex;
  align-items: center;
  background-color: rgba(153, 153, 153, 0.3);
  padding-left: 16px;
}
.session-second-col {
  height: 66px;
  display: flex;
  align-items: center;
  background-color: rgba(153, 153, 153, 0.3);
  padding-left: 16px;
}
/* .session-details-second-col div{
    background-color: rgba(153, 153, 153, 0.3);
    height: 66px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 7px;
    padding-right: 7px;
} */
.session-details-second-col {
  height: 66px;
  display: flex;
  align-items: center;
  background-color: rgba(153, 153, 153, 0.3);
  width: 100%;
}
.invitationListeDetailsLastCol {
  border-bottom-left-radius: 1.4ch;
  border-bottom-right-radius: 1.4ch;
}
.invitationEmailText {
  font-size: 16px;
  color: #000000;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 370px;
}
.session-type-table {
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  flex-wrap: wrap;
  width: 73px;
  margin-right: 20px;
}

.session-projet-table {
  font-size: 16px;
  font-weight: 700;
  flex-wrap: wrap;
  width: 300px;
  margin-right: 20px;
}
.session-invite-user {
  font-size: 18px;
  color: #999999;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: #000000;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
}
.session-invite-member-text {
  font-size: 18px;
  color: white;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  cursor: pointer;
}
.session-invite-member-container {
  background-color: #000000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invitationErrorMessage {
  color: #ff2b00;
  font-weight: 700;
  font-size: 12.8px;
}
.session-name-width {
  margin-left: 40px;
  width: 45ch;
}
.session-table-btn {
  display: flex;
  margin-left: auto;
}
#dropdownInvitation .dropdown-toggle::after {
  display: inline-block;
}
.invitationDropDownContainer {
  max-width: 10rem;
  margin-left: auto;
  font-weight: 700;
}
.session-text-container {
  width: 128px;
  margin-left: auto;
  font-weight: 700;
  margin-right: 3rem;
}
.projectStatusDropDownContainer {
  max-width: 14rem;
  margin-left: auto;
}
.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  display: none;
}
.css-1s2u09g-control,
.css-1insrsq-control,
.css-1pahdxg-control:hover,
.css-1pahdxg-control {
  border: 2px solid rgba(153, 153, 153, 0.1) !important;
  border-radius: 1.4ch !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.css-1s2u09g-control:hover {
  border: 2px solid rgba(153, 153, 153, 0.3) !important;
}
.invitationInputDetails {
  width: 160px;
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}
.fiche-user-input-classname {
  max-width: 10rem !important;
  border-radius: 1.4ch;
  border: 0;
}
.fiche-user-input-classname:hover {
  border: 0;
}
.invitationSecondInputDetails {
  width: 160px;
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}
.css-26l3qy-menu {
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3) !important;
  border-radius: 1.4ch !important;
  background-color: #ffffff !important;
}
.invitationSecondInputDetails .css-1s2u09g-control {
  background-color: transparent !important;
}
.invitationSecondInputDetails .css-1s2u09g-control :focus {
  background-color: transparent !important;
}
.invitationSecondInputDetails .css-1pahdxg-control {
  background-color: transparent !important;
}
.css-1n7v3ny-option {
  border-radius: 1.4ch !important;
  background-color: rgba(0, 85, 255, 0.1) !important;
}
.css-qc6sy-singleValue {
  /*    font-size: 16px !important; */
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000 !important;
}
.css-1pndypt-Input {
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 16px;
}
.css-14el2xx-placeholder {
  font-size: 16px;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #999999 !important;
}
.invitationBtnDeleteContainer {
  cursor: pointer;
}

.fiche-utilisateur-container {
  margin-top: 24px;
  margin-right: 31px;
  margin-left: 31px;
  display: flex;
}
.session-container {
  margin-top: 24px;
  margin-right: 31px;
  margin-left: 31px;
  display: flex;
}
.user-file-modal-big-container {
  height: calc(var(--vh, 1vh) * 100 - 209px);
  overflow-y: auto;
}
.sesion-details-big-container {
  height: calc(var(--vh, 1vh) * 100 - 183px);
  overflow-y: auto;
}
.session-invite-modal-big-container {
  height: calc(var(--vh, 1vh) * 100 - 242px);
  overflow-y: auto;
  margin-left: 16px;
}
.session-search-icon {
  position: absolute;
  top: 8px;
  left: 18px;
}
.ajouter-option--modal-big-container {
  height: calc(var(--vh, 1vh) * 100 - 250px);
  overflow-y: auto;
}
.user-file-Btn-container {
  display: flex;
  flex-direction: column;
  text-align: end;
}
.session-Btn-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.user-file-first-details-container {
  display: block;
  width: 55%;
}
.session-file-first-details-container {
  display: block;
  width: 47%;
}
.user-file-avatar-container {
  display: block;
  width: 35%;
  position: relative;
}
.user-file-title {
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
}
.user-file-email {
  font-weight: 800;
  font-size: 20px;
}
.fiche-user-alert-container {
  background-color: rgba(0, 85, 255, 0.1);
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.fiche-user-role-container {
  background-color: rgba(153, 153, 153, 0.3);
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.session-role-container {
  background-color: rgba(153, 153, 153, 0.3);
  padding: 16px;
  display: flex;
  align-items: center;
}
.fiche-user-alert-icon {
  color: #0055ff;
  margin-right: 16px;
}
.fiche-user-icon-minus {
  color: #000000;
  width: 2rem;
  height: 2rem;
  margin-right: 16px;
  cursor: pointer;
}
.fiche-user-icon-plus {
  color: #000000;
  width: 2rem;
  height: 2rem;
  margin-left: 16px;
  cursor: pointer;
}
.add-option-number-admin {
  background: rgba(153, 153, 153, 0.1);
  border-radius: 1.4ch;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fiche-user-alert-text {
  font-size: 18px !important;
  color: #000000;
  font-weight: 700;
}
.fiche-user-alert-second-text {
  font-size: 18px !important;
  color: #000000;
  font-weight: 800;
  padding-left: 5px;
}
.fiche-user-role-title-text {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
}
.fiche-user-role-status-text {
  font-size: 16px;
  color: #000000;
  font-weight: 800;
}
.fiche-user-owner-text {
  font-size: 16px;
  color: #000000;
  font-weight: 800;
}
.add-option-first-text-collapse {
  font-size: 20px;
  color: #000000;
  font-weight: 800;
}
.add-option-total-text {
  font-size: 20px;
  color: #000000;
  font-weight: 800;
  margin-right: 5rem;
  min-width: 116px;
}
.add-option-Second-total-text {
  font-size: 20px;
  color: #000000;
  font-weight: 800;
  margin-right: 6rem;
  min-width: 116px;
}
.add-option-admin-nbr-container {
  width: 40px;
  height: 40px;
  font-weight: 800;
  color: #ffffff;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0055ff;
  margin-right: 48px;
}
.ajouter-option-container-icon-nbr {
  display: flex;
  position: relative;
  align-items: center;
}
.add-option-add-nbr-admin-container {
  width: 40px;
  height: 40px;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 48px;
  margin-left: 300px;
}
.add-option-add-total-nbr-admin-container {
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}
.add-option-check-icon {
  color: #0055ff;
  width: 2rem;
  height: 2rem;
  background-color: rgba(0, 85, 255, 0.1);
  border-radius: 50%;
}
.add-option-first-icon {
  width: 2.2rem;
  height: 2.2rem;
  color: #32c832;
  position: absolute;
  top: 2px;
  right: 0px;
}
.add-option-second-icon {
  width: 2.2rem;
  height: 2.2rem;
  color: #0055ff;
  position: absolute;
  top: 2px;
  right: 0px;
}
.add-option-btn-container {
  display: flex;
  margin-top: 48px;
  margin-left: 16px;
  margin-bottom: 48px;
  padding-top: 24px;
}
.add-option-collapse-first-text {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  margin-top: 24px;
  margin-left: 48px;
}
#tags-dropdown .btn-primary:disabled {
  background-color: #f3f3f3 !important;
}
.custom-btn-without-icon {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro-sidebar.collapsed #dropdownWorkspace .dropdown-menu.show,
.pro-sidebar.collapsed #dropdownWorkspace .dropdown-menu.show .dropdown-menu {
  width: 60px !important;
  min-width: 0px !important;
}
.pro-sidebar.collapsed #dropdownWorkspace .dropdown-toggle::after {
  display: none;
}
.active-ws {
  max-width: 130px;
  display: inline-block;
  height: 22px;
}
#user-tab-header.show.dropdown button:focus {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #ffffff !important;
}
#user-tab-header.dropdown button:focus {
  background-color: inherit !important;
  border-color: inherit !important;
  color: inherit !important;
}
#session-switch .react-switch-handle {
  box-shadow: none !important;
}
.session-table-add-btn {
  text-align: right;
  margin-left: auto;
}
.session-member-container {
  width: 350px;
  display: flex;
  flex-wrap: wrap;
}
.session-details-member-container {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
}
.projects-check {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-bottom: 6px;
}

.score-text {
  font-size: 16px;
  color: #000000;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
}
.score-text-status {
  font-size: 16px;
  color: #000000;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 20%;
}
.sessionRowSelected {
  cursor: pointer;
}
.score-box {
  width: 50px;
  height: 50px;
  background-color: #0055ff;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 800;
  color: white;
}
.session-details-table-header {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid rgba(153, 153, 153, 0.1);
}
.scoring-axes-container {
  margin-top: 24px;
  border-radius: 1.4ch;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 100px;
}
.rk-squared-value {
  width: 3.3em;
  border-radius: 6px;
  justify-content: center;
}
.rk-std-value {
  height: 3.3em;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
}
.session-text-table-col {
  font-size: 14px;
  font-weight: 700;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 7px;
  margin-right: 7px;
}
.session-display-tab {
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
}
.table-session-detials-container {
  border-radius: 1.4ch;
  border: 1px #999999 solid;
  margin-right: 16px;
  margin-bottom: 100px;
  overflow: auto;
  display: grid;
}
.score-card span {
  width: 70px;
  height: 44px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', serif;
  color: #000000;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
#session_votes_body,
#session_votes_consensus_body {
  overflow: auto !important;
  height: calc(var(--vh, 1vh) * 100 - 224px);
}
.vote-modal {
  height: calc(var(--vh, 1vh) * 100 - 164px);
}
.score-card {
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 1.4ch;
}
.score-card-selected span {
  width: 70px;
  height: 44px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-weight: 800;
  font-style: normal;
  font-family: 'Montserrat', serif;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}
.score-card-selected {
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3);
  background-color: #0055ff;
  overflow: visible;
  border-radius: 1.4ch;
}
@media (max-width: 1199px) {
  .axe-priorisation-btn-container {
    padding-right: 5px;
  }
  .axeValueInputDetails {
    position: absolute;
    top: 5px;
    right: 40%;
    color: #000000;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
  }
  .user-file-avatar-container {
    margin-left: 3rem;
  }
  .add-option-Second-total-text {
    margin-right: 2.2rem;
  }
  .add-option-total-text {
    margin-right: 2.2rem;
  }
  .add-option-add-total-nbr-admin-container {
    margin-left: 24px;
  }
  .add-option-admin-nbr-container {
    margin-left: 24px;
  }
  .session-first-table {
    width: 480px;
    border-radius: 1.4ch;
    border: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .axe-priorisation-btn-container {
    padding-right: 10px;
  }
}
@media (max-width: 1600px) {
  .fiche-user-alert-text {
    font-size: 22px;
  }
  .fiche-user-alert-second-text {
    font-size: 22px;
  }
}
.react-datepicker {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #000000;
  line-height: 1.4;
  border: 0;
  border-radius: 1.4ch;
  box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.3) !important;
}
.style-datepicker-input {
  padding: 0.375rem 0.75rem;
  max-width: 16ch;
}
.react-datepicker-wrapper {
  width: auto;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle:after,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle:before,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle:after,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle:before {
  border: 0;
}
.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 1px;
}
.react-datepicker__navigation {
  top: 8px;
}
.react-datepicker__navigation-icon:before {
  border-color: #999999;
}
.react-datepicker__navigation:hover :before {
  border-color: rgba(153, 153, 153, 0.3);
}
.react-datepicker__header {
  background-color: rgba(153, 153, 153, 0.1);
  border-bottom: 0;
  border-top-left-radius: 1.4ch !important;
  border-top-right-radius: 1.4ch !important;
}
.react-datepicker__day-name {
  font-weight: 700;
  color: #999999;
}
.react-datepicker__day {
  font-weight: 600;
}
.react-datepicker__day:hover {
  background-color: rgba(153, 153, 153, 0.3);
  border-radius: 50%;
}
.react-datepicker__day--weekend {
  color: #999999 !important;
}
.react-datepicker__day--today {
  font-weight: 800;
  text-decoration: underline;
  text-decoration-thickness: 0.15em;
}
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #0055ff;
  border-radius: 50%;
  font-weight: 800;
  color: #ffffff !important;
}
.react-datepicker__day--outside-month {
  color: rgba(153, 153, 153, 0.3);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #ffffff;
  color: inherit;
}
.datepicker-icon {
  position: relative;
  right: 40px;
  color: #999999;
}

/* crisp overwrite */
.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
  width: 42px !important;
  height: 42px !important;
  display: block !important;
  border-radius: 100% !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05) !important;
  transition: transform 0.15s ease-in-out !important;
}
/* crisp overwrite */
.crisp-client
  .cc-tlyw[data-last-operator-face='false']
  .cc-kxkl
  .cc-nsge
  .cc-imbb
  .cc-11f2[data-is-ongoing='false']
  .cc-15e7 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB3aWR0aD0iMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxmaWx0ZXIgaWQ9ImEiIGhlaWdodD0iMTM4LjclIiB3aWR0aD0iMTMxLjQlIiB4PSItMTUuNyUiIHk9Ii0xNS4xJSI+PGZlTW9ycGhvbG9neSBpbj0iU291cmNlQWxwaGEiIG9wZXJhdG9yPSJkaWxhdGUiIHJhZGl1cz0iMSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHk9IjEiIGluPSJzaGFkb3dTcHJlYWRPdXRlcjEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIxIi8+PGZlQ29tcG9zaXRlIGluPSJzaGFkb3dCbHVyT3V0ZXIxIiBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0ib3V0IiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjA3IDAiLz48L2ZpbHRlcj48cGF0aCBpZD0iYiIgZD0iTTE0LjIzIDIwLjQ2bC05LjY1IDEuMUwzIDUuMTIgMzAuMDcgMmwxLjU4IDE2LjQ2LTkuMzcgMS4wNy0zLjUgNS43Mi00LjU1LTQuOHoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiLz48dXNlIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjYiIvPjwvZz48L3N2Zz4=) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: inline-block !important;
  width: 25px !important;
  height: 21px !important;
  top: 12.25px !important;
  left: 9.25px !important;
}
/* crisp overwrite */
.crisp-client
  .cc-tlyw[data-last-operator-face='false'][data-full-view='false']
  .cc-kxkl
  .cc-nsge[data-maximized='true']
  .cc-imbb
  .cc-11f2[data-is-ongoing='false']
  .cc-15e7 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5LjM5OSAyLjA0YTEuMzM0IDEuMzM0IDAgMDAtMi4yOS0uOTNsLTcuMDU2IDcuMDU3TDIuOTk1IDEuMTFBMS4zMzMgMS4zMzMgMCAxMDEuMTEgMi45OTVsNy4wNTcgNy4wNThMMS4xMSAxNy4xMWExLjMzNCAxLjMzNCAwIDEwMS44ODUgMS44ODVsNy4wNTgtNy4wNTcgNy4wNTcgNy4wNTdhMS4zMzQgMS4zMzQgMCAxMDEuODg1LTEuODg1bC03LjA1Ny03LjA1NyA3LjA1Ny03LjA1OGExLjMzMiAxLjMzMiAwIDAwLjQwNC0uOTU1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: inline-block !important;
  width: 12px !important;
  height: 12px !important;
  top: 15px !important;
  left: 15px !important;
}
